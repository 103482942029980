import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ModalEdit: false,
  },

  mutations: {
    toggleModal(state, value) {
      state.ModalEdit = value
    },
  }
})