<template>
  <div>
    <v-dialog v-model="ModalEdit" :max-width="dekstop">
      <v-card>
        <v-card-title class="text-h5 primary white--text">
          Edit Foto
        </v-card-title>

        <div class="text-center pa-5">
          <div>
            <v-alert v-model="alertGagal" type="error" dense dismissible>
              {{ alertText }}
            </v-alert>
          </div>

          <v-row>
            <v-col cols="12" md="6">
              <cropper
                :src="imageUpload"
                ref="cropper"
              />

              <v-file-input
                v-model="filedFoto"
                class="mt-5"
                accept="image/png, image/jpeg, image/bmp"
                placeholder="Pilih Foto Anda"
                prepend-icon="mdi-camera"
                label="Foto Profil"
                @change="onFile"
              ></v-file-input>
              <v-btn
                @click="crop()"
                color="info"
                block
                rounded
                :disabled="filedFoto == null"
              >
                <v-icon left>mdi-crop</v-icon>
                Crop
              </v-btn>
            </v-col>

            <v-col cols="12" md="6">
              <v-divider class="mb-2"></v-divider>
              <h3 class="mb-5">PREVIEW</h3>
              <v-img :src="image" max-width="200" class="mx-auto"></v-img>
            </v-col>
          </v-row>
        </div>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mx-2"
            color="primary"
            text
            @click="uploadImage()"
            :disabled="image == null"
            >Simpan</v-btn
          >
          <v-btn class="mx-2" text @click="modalClose()"> Tutup </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Cookie from '@/helper/cookie.js'
import Auth from '@/helper/auth.js'

import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

import refreshView from "@/store/photo/refreshView";
import modal_editState from "@/store/photo/modal_edit";

export default {
  components: {
    Cropper,
  },

  computed: {
    ModalEdit: {
      get() {
        return modal_editState.state.ModalEdit
      },

      set(value) {
        modal_editState.commit('toggleModal', value)
      },
    },
  },

  created() {
    this.$vuetify.breakpoint.name == 'lg' ||
    this.$vuetify.breakpoint.name == 'xl'
      ? (this.dekstop = '50%')
      : (this.dekstop = '100%')
  },

  data: () => ({
    token: '',
    get_nip: '',
    dekstop: '50%',

    alertGagal: false,
    alertText: '',

    coordinates: {
      width: 0,
      height: 0,
      left: 0,
      top: 0,
    },

    image: null,
    filedFoto: null,
    imageUpload: null,
  }),

  async mounted() {
    this.token = await Cookie.get('token')
  },

  methods: {
    onFile(value) {
      this.imageUpload = URL.createObjectURL(value)
    },

    default() {
      this.coordinates = {
        width: 0,
        height: 0,
        left: 0,
        top: 0,
      }

      this.image = null
      this.filedFoto = null
      this.imageUpload = null
    },

    crop() {
      const { coordinates, canvas } = this.$refs.cropper.getResult()
      this.coordinates = coordinates
      
      // You able to do different manipulations at a canvas
      // but there we just get a cropped image, that can be used
      // as src for <img/> to preview result
      this.image = canvas.toDataURL()
    },

    uploadImage() {
      const { canvas } = this.$refs.cropper.getResult()
      if (canvas) {
        const data = new FormData()
        canvas.toBlob((blob) => {
          data.append('FOTO', blob)

          const url = process.env.VUE_APP_ASN + 'photos/' + this.$route.params.id;

          this.http
            .post(url, data, {
              headers: {
                Authorization: 'Bearer ' + this.token,
              },
            })
            .then((res) => {
              if (res.data.success) {
                refreshView.commit('refreshData', true)
                this.modalClose()
              } else {
                this.alertText = res.data.message
                this.alertGagal = true
              }
            })
            .catch((error) => {
              if (error.response.status == 401) {
                Auth.logout(this.token)
              } else if (error.response) {
                console.log(error.response.status)
              } else {
                console.log('Error', error.message)
              }
            })

          // Perhaps you should add the setting appropriate file format here
        }, 'image/jpeg')
      }
    },

    modalClose() {
      this.default()
      this.ModalEdit = false
    },
  },
}
</script>